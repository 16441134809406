/*
  Project: australian-associated-press
  Author: Puck Willis
 */
import $ from 'jquery';
import menu from './modules/menu';
import common from './modules/common';
import loadmore from './modules/loadmore';
import login from './modules/login';
import card from './modules/card';


$();
menu();
common();
loadmore();
login();
card();