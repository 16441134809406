import $ from 'jquery';

const common = name => {
  $(document).ready(function(){
    //console.log('go owl go');

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
    
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
    
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };




    $(".j-panel-carousel").each(function(){
        $(this).owlCarousel({
            items: 1,
            // lazyLoad:true,
            // loop:true,
            thumbs: true,
            // thumbsPrerendered: false,
            // URLhashListener:true,
            // pagination: true,
            dots: true,
            nav: true,
            navText: [
                "",
                ""
            ],
            autoplayTimeout:8000,
            autoplayHoverPause:false
            
        });
    });
    
    $('.j-panel-carousel').on('changed.owl.carousel', function(event) {
        // console.log('changing carousel item');
        var currentPanel = $(this).data('postno');
        // console.log(currentPanel);
        var currentItem = currentPanel + '-' + (event.item.index + 1);
        // console.log(currentItem);
        $('.'+currentPanel).removeClass('d-none');
        $('.'+currentPanel).addClass('d-none');
        $('#'+currentItem).removeClass('d-none');
    });
    $(".j-quote-carousel").each(function(){
        $(this).owlCarousel({
            items: 1,
            // lazyLoad:true,
            // loop:true,
            thumbs: true,
            // thumbsPrerendered: false,
            // URLhashListener:true,
            // pagination: true,
            dots: true,
            nav: true,
            navText: [
                "",
                ""
            ],
            autoplayTimeout:8000,
            autoplayHoverPause:false
            
        });
    });   

    $(window).on('resize scroll', function() {
        // console.log($('.owl-carousel').isInViewport());
        $('.owl-carousel').each(function(){
          if ($(this).isInViewport()) {
            $(this).closest('.owl-carousel').trigger('play.owl.autoplay');
          }
        });
    });

    // $('.product-slider li').on('mouseenter',function(e){
    //   $(this).closest('.owl-carousel').trigger('play.owl.autoplay');
      
    // });
      
    // $('.product-slider li').on('mouseleave',function(e){
    //   $(this).closest('.owl-carousel').trigger('stop.owl.autoplay');
    // });


    $('.j-expitem-select').click(function(){
      
      const toExpand = $(this);
      if (toExpand.hasClass('c-page-about--selected')){
        $('.j-expitem-select').removeClass('c-page-about--selected');
        $('.j-expitem').removeClass('d-none');
        $('.j-expitem').addClass('d-none');
      } else {
        expandItem(toExpand.data('pos'));
      }
    });

    $('.j-profile').click(function(){
      const toExpand = $(this);
      if (toExpand.hasClass('c-profile--selected')){
        $('.j-profile').removeClass('c-profile--selected');
        $('.j-profile-expand').removeClass('d-none');
        $('.j-profile-expand').addClass('d-none');
      } else {
        expandProf('#'+toExpand[0].id);
      }
    });
    $('.j-profile-close').click(function(){
      $('.j-profile').removeClass('c-profile--selected');
      $('.j-profile-expand').removeClass('d-none');
      $('.j-profile-expand').addClass('d-none');
    });

    const expandProf = function(item){
      const expItem = $(item);
      $('.j-profile').removeClass('c-profile--selected');
      $('.j-profile-expand').removeClass('d-none');
      $('.j-profile-expand').addClass('d-none');
      expItem.addClass('c-profile--selected');
      const contOpen = expItem.data('expand');
      $(contOpen).removeClass('d-none');
      window.location.hash = item;
      var y = $(window).scrollTop();  //your current y position on the page
      var winwidth = $(window).width();
      if (winwidth < 768){
        $(window).scrollTop(y-130);
      } else if (winwidth > 767 && winwidth < 991){
        $(window).scrollTop(y-130);
      } else {
        $(window).scrollTop(y-100);
      }
    };

    const expandItem = function(item){
      const posItem = $(item);
      const expItem = $($(posItem.data('item'))[0]);
      $('.j-expitem-select').removeClass('c-page-about--selected');
      $('.j-expitem').removeClass('d-none');
      $('.j-expitem').addClass('d-none');
      expItem.addClass('c-page-about--selected');
      window.location.hash = item;
      const contOpen = expItem.data('item');
      $(contOpen).removeClass('d-none');
      var y = $(window).scrollTop();  //your current y position on the page
      var winwidth = $(window).width();
      //console.log(winwidth);
      if (winwidth < 768){
        $(window).scrollTop(y-100);
      } else if (winwidth > 767 && winwidth < 991){
        $(window).scrollTop(y-100);
      } else {
        
      }
    };
  
    const position = function(){
      const urlHash = window.location.hash;
      if (urlHash){
        expandItem(urlHash); 
      }
    };
    position();
    $('.j-menu-position').click(function(){
      position();
    });

    $('#j-dropdown-toggle').click(function(){     
      $('.j-dropdown-list').toggleClass('d-none');
      $('.j-dropdown-arrow').toggleClass('c-contact-dropdown__arrow--active');
    });

    $('.j-dropdown-item').click(function(){
      $('.j-dropdown-item').each(function(){
        $(this).removeClass('c-contact-dropdown__listitem--selected');
      });
      $(this).addClass('c-contact-dropdown__listitem--selected');
      var itemno = $(this).data('itemno');
      var itemname = $(this).data('itemname');
      setTimeout(function(){
        $('#j-dropdown-input').val(itemno);
        $('#j-dropdown-selected').html(itemname);
        $('.j-dropdown-list').toggleClass('d-none');
        $('.j-dropdown-arrow').toggleClass('c-contact-dropdown__arrow--active');
      },200);
    });

  });
};
export default common;
