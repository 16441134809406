import $ from 'jquery';

const menu = name => {
  const pageWindow = $(window);
  const hamBurg = $('.site-nav__menu-hamburger');
  const menuItem = $('.site-menu__item');
  const expandItem = $('.j-submenuexpand');
  const underLay = $('.site-menu__overlay');
  if (pageWindow.width() > 991) {
      menuItem.hover(function(){
        $(this).toggleClass('site-menu--selected');
    });
  }
  if (pageWindow.width() < 991) {
    if (hamBurg.length) {
      hamBurg.click(function(){
        const mobmenu = $('.site-menu');
        mobmenu.toggleClass('site-menu--open');
        hamBurg.toggleClass('site-menu--selected-hamburger');
        $('.j-moblogo').toggleClass('navigation-logo__img--dark');
        $('.j-header').toggleClass('site-header--dark');
        $('j-menu-item').removeClass('d-none');
        underLay.toggleClass('d-none');
      });
    }
    if (underLay.length) {
      underLay.click(function(){
        const mobmenu = $('.site-menu');
        mobmenu.toggleClass('site-menu--open');
        hamBurg.toggleClass('site-menu--selected-hamburger');
        $('.j-moblogo').toggleClass('navigation-logo__img--dark');
        $('.j-header').toggleClass('site-header--dark');
        underLay.toggleClass('d-none');
      });
    }
    expandItem.click(function(){
      const toExpand = $(this);
      const toParent = toExpand[0].parentNode;
      toExpand.toggleClass('site-sub-menu__expanded');
      $(toParent).toggleClass('site-menu--selected');
      // $('.j-menu-item').toggleClass('d-none');
      // $(toParent).removeClass('d-none');
    });
  }
  
  $(".j-menu-item>a[href='"+window.location.href+"'].site-menu__link").addClass('site-menu__item--current');
};
export default menu;
