import $ from 'jquery';

const login = name => {
  $(document).ready(function(){
    $('.j-login').click(function(){
      console.log('click');
      $('#loginForm').toggleClass('d-block');
    });
    // $('#j-modaloverlay').click(function(){
    //   $('#loginForm').toggleClass('d-block');
    // });
    $('#j-modalclose').click(function(){
      $('#loginForm').toggleClass('d-block');
    });
  });
};
export default login;
