import $ from 'jquery';

const loadmore = name => {
  $(document).ready(function(){
    if ($('#j-loadmore').length){
      var setwaypoint = function(){
        var waypoint = new Waypoint({
          element: $('#j-loadmore'),
          offset: '80%',
          handler: function(direction) {
            if (direction == 'down'){
              waypoint.destroy();
              var loadOptions = $('#j-loadmore');
              var postOffset = loadOptions.data('offset');
              var loadNo = loadOptions.data('loadno');
              loadOptions.data('offset', postOffset + loadNo);
              var cat = loadOptions.data('category');
              var meta = loadOptions.data('meta');
              var search = loadOptions.data('search');
              var site = loadOptions.data('site');
              var settings = {
                "container" : loadOptions.data('loadcont'),
                "colclass"  : loadOptions.data('colclass'),
                "cardclass" : loadOptions.data('cardclass'),
                "imgsize"   : loadOptions.data('imgsize'),
                "hr"        : loadOptions.data('hr'),
                "site"      : site
              };
              if (search && search != ''){
                var getUrl = 'https://loadmore.aap.com.au/post?search=' + search + '&postOffset=' + postOffset + '&perPage=' + loadNo;
              } else {
                var getUrl = 'https://loadmore.aap.com.au/category?category=' + cat + '&postOffset=' + postOffset + '&perPage=' + loadNo;
              }
              //https://loadmore.aap.com.au/category?category=cat&postOffset=offset&perPage=perpage
              //console.log(getUrl);
              $.ajax({
                url: getUrl,
                type: 'get',
                data: '',
                dataType: 'json',
                success: function(data) {
                  if (data.length > 0){
                    var last = false;
                    for (var i=0;i<data.length;i++){
                      if (i == data.length - 1) { last = true; };
                      appendPost(settings,data[i],last);
                    }
                  }
                },
                error: function(data) {
                  console.log('failure getting article',settings,data);
                }
              });
            }
          }
        })
        
      }
      var appendPost = function(settings,item,last){
        if (item.featured_media != 0){
          $.ajax({
            url: 'https://loadmore.aap.com.au/mda?id='+ item.featured_media,
            type: 'get',
            data: '',
            dataType: 'json',
            success: function(data) {
              if (data.media_details.sizes.medium){
                appendSinglePost(item,settings,data.media_details.sizes.medium.source_url);
              } else if(data.guid.rendered) {
                appendSinglePost(item,settings,data.guid.rendered);
              } else {
                console.log('no image found in post');
                appendSinglePost(item,settings,'',last);
              }
              if (last){setwaypoint()};
            },
            error: function(data) {
              console.log('failure getting image',item);
            }
          });
        } else {
          console.log('no image found in post');
          appendSinglePost(item,settings,'');
          if (last){setwaypoint()};
        }
      }
      var appendSinglePost = function(item,settings,featured){
        var image = '';
        if (featured != ''){
          image = '<figure>\
                    <img sizes="'+settings.imgsize+'" src="'+featured+'">\
                  </figure>';
        }
        var imageless = '';
        if (image == ''){
          imageless = ' card-noimage';
        }
        var post = 
          '<div class="'+settings.colclass+'">\
            <hr class="'+settings.hr+'">\
            <div class="'+settings.cardclass+'">\
              <a href="'+item.link+'" class="card article'+imageless+'">\
                <article>\
                  '+image+'\
                  <div class="a-content">\
                    <p class="a-subject">'+item.meta.subject+'</p>\
                    <h2>'+item.title.rendered+'</h2>\
                    <p class="a-excerpt">'+item.excerpt.rendered.replace(/<[^>]*>?/gm, '')+'</p>\
                  </div>\
                </article>\
              </a>\
            </div>\
          </div>';
        var container = $(settings.container);
        container.append(post);
      }
      console.log('set initial waypoint');
      setwaypoint();
    }
  });

};
export default loadmore;


//http://wordpress-dev.test/?rest_route=/wp/v2/posts/&categories=24&order=asc&offset=4